import React from "react";
import Box from "@material-ui/core/Box";

function Logotipo() {
  return (
    <Box display="flex" justifyContent="center" style={{ padding: "0px" }}>
      <p
        style={{
          fontSize: "55px",
          textAlign: "center",
          font: "normal normal bold 58px/1.4em Tahoma",
        }}
      >
        AxerWeb <span style={{ color: "#66BE67", fontSize: "65px" }}>&</span>{" "}
        Sua Empresa
        <br />
        <span style={{ color: "#66BE67", fontSize: "65px" }}> = </span>{" "}
        Eficiência e Competitividade
      </p>
    </Box>
  );
}

export default Logotipo;
