import React from "react";
import Box from "@material-ui/core/Box";

function Rodape() {
  return (
    <footer
      style={{
        backgroundColor: "#CBE2A2",
        height: "150px",
        textAlign: "right",
        padding: "10px",
      }}
      className="footer"
    >
      <Box
        display="flex"
        flexGrow="1"
        justifyContent="space-evenly"
        style={{ outline: "3px dotted red", margin: "10px" }}
      >
        <div style={{ outline: "3px dotted red", margin: "10px" }}>
          <p>AxerWeb Sistemas de Gestão </p>
          <p>Desenvolvimento de Aplicativo e Sistema Personalizado</p>
        </div>
        <div style={{ outline: "3px dotted red", margin: "10px" }}>
          <p>
            Endereço: ​ Av. Monteiro Lobato, 41 sala 04 Cidade Nobre Ipatinga MG
          </p>
          <p>Trade Center Nobre</p>
        </div>
        <div style={{ outline: "3px dotted red", margin: "10px" }}>
          <p>
            Contato: ​ Fone.: (31) 3616-2843 / 98801-4550 falecom@axerweb.com.br
          </p>
          <p>Suporte técnico 98377-4634 suporte@axerweb.com.br</p>
        </div>
      </Box>
    </footer>
  );
}

export default Rodape;
