import React from "react";
import ReactDOM from "react-dom";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Home from "./paginas/home/Home";
import * as serviceWorker from "./serviceWorker";

const axerweb = createMuiTheme({
  palette: {
    primary: {
      light: "#66BE67",
      main: "#49904D",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      //light: "#0066ff",
      main: "#213242",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#ffcc00",
    },
    // error: will use the default color
  },
});

ReactDOM.render(
  <React.StrictMode>
    <MuiThemeProvider theme={axerweb}>
      <Home />
    </MuiThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();
