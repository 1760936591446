import React from "react";
import Logomarca from "../../componentes/visuais/Logomarca";
import MenuPrincipal from "../../componentes/visuais/MenuPrincipal";
import Logotipo from "../../componentes/visuais/Logotipo";
import DescricaoEmpresa from "../../componentes/visuais/DescricaoEmpresa";
import Rodape from "../../componentes/visuais/Rodape";

function Home() {
  return (
    <>
      <Logomarca />
      <MenuPrincipal />
      <Logotipo />
      <DescricaoEmpresa />
      <Rodape />
    </>
  );
}

export default Home;
