import React from "react";
import Box from "@material-ui/core/Box";

function DescricaoEmpresa() {
  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      style={{
        fontSize: "22px",
        textAlign: "justify",
      }}
    >
      <span
        style={{ alignSelf: "center", fontSize: "18px", fontWeight: "bold" }}
      >
        Sistema de Gestão de Empresas
      </span>
      <p
        style={{
          width: "65%",
          alignSelf: "center",
          fontSize: "22px",
          textAlign: "justify",
        }}
      >
        On-line desde 2007, o Web Gerente vem ajudando empresas de diversos
        seguimentos a gerenciar melhor as suas rotinas diárias. São centenas de
        usuários conectados diariamente e todos satisfeitos e confiantes na
        solução proposta.
      </p>
    </Box>
  );
}

export default DescricaoEmpresa;
