import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import HomeIcon from "@material-ui/icons/Home";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
  link: {
    display: "flex",
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
}));

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

export default function IconBreadcrumbs() {
  const classes = useStyles();

  return (
    <>
      <Box display="flex" justifyContent="center">
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            color="inherit"
            href="/"
            onClick={handleClick}
            className={classes.link}
          >
            <HomeIcon className={classes.icon} />
            Página Inicial
          </Link>
          <Link
            color="inherit"
            href="/clientes"
            onClick={handleClick}
            className={classes.link}
          >
            <BusinessCenterIcon className={classes.icon} />
            Clientes
          </Link>
          <Link
            color="inherit"
            href="/videos"
            onClick={handleClick}
            className={classes.link}
          >
            <VideoLibraryIcon className={classes.icon} />
            Vídeos de Treinamento
          </Link>

          <Link
            color="inherit"
            href="/videos"
            onClick={handleClick}
            className={classes.link}
          >
            <LiveHelpIcon className={classes.icon} />
            FAQ
          </Link>

          <Link
            color="inherit"
            href="/contato"
            onClick={handleClick}
            className={classes.link}
          >
            <AlternateEmailIcon className={classes.icon} />
            Contato
          </Link>
        </Breadcrumbs>
      </Box>
      <hr
        style={{
          textAlign: "center",
          width: "980px",
          display: "block",
          height: "1px",
          border: "0",
          borderTop: "1px solid #ccc",
          margin: "0 auto",
          padding: "0",
          marginTop: "10px",
        }}
      />
    </>
  );
}
