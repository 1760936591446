import React from "react";
import Logo from "../../assets/imagens/logo.png";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  logomarca: {
    padding: "15px",
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
}));

function Logomarca() {
  const classes = useStyles();

  return (
    <Box display="flex" justifyContent="center" className={classes.logomarca}>
      <Box>
        <img src={Logo} alt="AxerWeb Sistemas de Gestão" />
      </Box>
    </Box>
  );
}

export default Logomarca;
